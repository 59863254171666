@tailwind base;
@tailwind components;
@tailwind utilities;


.infinite-scroll-component {
  overflow: visible!important;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#chatra:not(.chatra--expanded), #chatra.chatra--transparent {
  visibility: hidden !important;
  opacity: 0 !important;
  pointer-events: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

body {
  @apply text-neutral-900;
  min-width: 320px;
}

* {
  font-family: 'Inter', sans-serif;
  /*font-family: 'Lab Grotesque', sans-serif;*/
  outline: none;
}

h1 {
  @apply font-semibold text-3xl;
}
h2 {
  @apply font-semibold text-2xl;
}
h3 {
  @apply font-semibold text-xl;
}
h4 {
  @apply font-semibold text-lg;
}
h5 {
  @apply font-medium;
}

/*

.link {
  @apply px-4 py-2 -mx-4 -my-2 rounded-2xl;
}

.link:hover {
  @apply hover:bg-gray-100;
}
 */
.link:hover {
  @apply text-blue-700;
}

.link:hover > span {
  @apply text-blue-700;
}

input:focus {
  outline: none;
}

input {
  @apply px-3 py-2 border rounded-md shadow-sm;
}

input[type='number'] {
  @apply border-gray-200 px-3 py-2 border rounded-md shadow-sm outline-none;
}

html,
body {
  padding: 0;
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,*/
  /*  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;*/
}


a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


[data-rsbs-backdrop] {
  z-index: 20!important;
}

[data-rsbs-overlay] {
  z-index: 40!important;
}

.bg-header [data-rsbs-header] {
  padding: 0!important;
}

.Drawer-Content {
  background: white;
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  padding-bottom: env(safe-area-inset-bottom);
}

.Drawer-DragObserver {
  flex-direction: column;
  justify-content: flex-end;
}

.Drawer-Overlay {
  background: rgba(0, 0, 0, 0.4);
  /*backdrop-filter: blur(8px);*/
}

.Drawer-Title {
  margin-bottom: -1px;
  height: unset!important;
  @apply h-4 rounded-t-3xl bg-white overflow-hidden;
}

.Drawer-Title:after {
  top: 8px;
  left: 0;
  right: 0;
  width: 34px;
  height: 4px;
  margin: 0 auto;
  content: "";
  opacity: 0.2;
  z-index: 2;
  position: absolute;
  transition: .2s transform;
  border-radius: 2.5px;
  background-color: #21201f;
}

ul.pad > li {
  @apply ml-8 pl-1;
}

.legal-page p {
  @apply my-2;
}

.legal-page li {
  @apply my-2;
}

.link-ext {
  @apply text-blue-700 hover:text-blue-500;
}

reach-portal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rc-time-picker-input {
  @apply p-2;
}

/*.swiper {*/
/*  width: 100%;*/
/*  overflow: visible!important;*/
/*}*/

.swiper-slide {
  height: auto!important;
}
/*@media (max-width: 768px) {*/
/*  .swiper {*/
/*    padding-left: 1rem!important;*/
/*    padding-right: 1rem!important;*/
/*  }*/
/*}*/
