.card {
    @apply whitespace-normal h-full inline-block mr-3 border-t border-l cursor-pointer transition-all border-r border-gray-200/70 w-52 md:w-64 p-4 shadow-lg shadow-gray-100 hover:shadow-gray-200 rounded-xl;
}

.title {
    @apply font-semibold text-lg;
}

.status {
    @apply font-medium text-sm text-gray-500;
}

.bar {
    @apply grid grid-cols-4 gap-1 mb-2;
}

.barSection {
    @apply h-1 w-full rounded-full overflow-hidden;
}

.barValue {
    @apply bg-blue-500 rounded-full;
    width: 100%;
    height: 100%;
    animation: barAnimation 3s infinite linear;
    transform-origin: 0% 50%;
}

@keyframes barAnimation {
    0% {
        transform:  translateX(0) scaleX(0);
    }
    40% {
        transform:  translateX(0) scaleX(0.8);
    }
    100% {
        transform:  translateX(100%) scaleX(0.5);
    }
}
