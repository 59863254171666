.base {
    @apply whitespace-nowrap flex items-center justify-center focus:outline-none transition-all;
}

.primary {
    @apply flex items-center justify-center rounded-lg focus:outline-none gap-1 shadow-lg transition ease-in-out;
}

.gray {
    @apply bg-gray-200/50;
}

.blue {
    @apply bg-blue-600/90 text-white;
}

.red {
    @apply shadow-red-400/20 text-white/90 bg-gradient-to-br from-red-400 via-red-500 to-red-500;
}

.rose {
    @apply shadow-rose-400/20 text-white/90 bg-gradient-to-br from-rose-400 via-rose-500 to-rose-500;
}

.green {
    @apply shadow-green-500/20 text-white/90 bg-gradient-to-br from-green-500 via-green-600 to-green-600;
}

.transparent {
    @apply text-blue-700 font-medium;
}

.shadow-white {
    @apply shadow-md shadow-gray-200/60 border bg-white hover:shadow-none;
}

.white {
    @apply border bg-white;
}

.md {
    @apply px-5 py-2.5 rounded-xl;
}

.sm {
    @apply px-3 py-1.5 rounded-xl;
}

.xs {
    @apply px-3 py-1.5 rounded-lg;
}

.lg {
    @apply px-6 py-3 rounded-2xl;
}

.xl {
    @apply px-6 py-3.5 rounded-2xl;
}
