.selected {
  @apply font-medium -mx-2 text-blue-600 flex gap-1 items-center hover:bg-blue-50 px-4 py-2 rounded-full cursor-pointer;
}

.popover {
  @apply rounded-2xl shadow-lg bg-white p-2 z-40 flex flex-col;
}

.item {
  @apply px-3 gap-2 items-center pr-10 flex py-3 hover:bg-gray-50 rounded-lg cursor-pointer;
}
