.wrapper {
    @apply md:p-0 w-full max-w-screen-2xl transition-all grid gap-8 grid-cols-9;
}

.empty {
    @apply text-center pt-4 text-gray-600 col-span-9;
}

.groups {
    @apply order-2 md:order-1 flex mt-6 flex-col col-span-9 md:col-span-7;
}

.product {
    @apply flex gap-3 rounded-xl cursor-pointer;
}

.image {
    @apply shrink-0 w-20 h-20 bg-gray-50 rounded-xl bg-cover;
}

.infoBlock {
    @apply w-full flex-col self-center leading-7 flex;
}

.categoriesBlock {
    @apply order-1 md:order-2 col-span-9 md:col-span-2 mt-6;
}

.categoryList {
    @apply grid grid-cols-2 md:grid-cols-1 gap-3;
}

.resultsPageButton_desktop {
    @apply order-3 col-span-9 md:col-span-6 mb-4 flex gap-1.5 justify-center font-medium p-3 text-blue-600 hover:bg-blue-50 rounded-xl cursor-pointer items-center;
}

.resultsPageButton_mobile {
    @apply bg-white border-t rounded-t-xl fixed left-0 p-4 bottom-0 w-full;
}

