.logo {
    @apply block transition ease-in-out bg-cover my-2;
    background-image: url('/logo-bg.svg');
    width: 60px;
    height: 60px;
}

@media (max-width: 768px) {
    .logo {
        width: 58px;
        height: 51px;
    }
}

.wrapper {
    @apply flex justify-between gap-2 w-full items-center px-4;
}

.searchBtn {
    @apply md:hidden text-blue-600 font-medium text-xs flex gap-0.5 flex-col px-8 items-center hover:text-blue-500 cursor-pointer;
}

.navContainer {
    @apply flex gap-2 items-center relative justify-around w-full md:w-fit;
}

.cityToggleBtn {
    @apply text-blue-600 font-medium text-xs flex gap-0.5 flex-col p-2 px-8 items-center md:flex-row md:text-base md:gap-1.5 hover:text-blue-500 cursor-pointer;
}

.cartBtn {
    @apply -mt-0.5 w-8 h-8 ;
}

.scrollShadow {
    box-shadow: 0 0 14px 4px rgb(0 0 0 / 5%);
}

.link {
    @apply hover:text-blue-700;
}
