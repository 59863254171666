.card {
    @apply relative flex flex-col w-full h-full cursor-pointer rounded-xl;
}

.previewContainer {
    @apply rounded-lg overflow-hidden  aspect-square;
    padding-top: 100%;
    height: 0;
    position: relative;
    user-select: none;
}

.cartBtnWrap {
    @apply pt-3 flex gap-2 lg:hidden flex-col items-end mt-auto;
}

.addToCartBtn {
    @apply hidden lg:block transition-all ease-in-out absolute top-3 right-0 bg-white rounded-xl pl-4 p-2 z-10;
}

.title {
    @apply leading-6 font-medium text-base mb-1.5 h-full lg:h-auto;
}

.priceWrap {
    @apply hidden lg:flex gap-1 flex-col;
}

.qtyWrap {
    @apply flex w-full flex-row items-center justify-between gap-3;
}

.heartIcon {
    filter: drop-shadow(0px 0 4px rgba(0, 0, 0, 0.6));
    @apply w-8 h-8 text-white opacity-40 hover:opacity-80 hover:scale-110 active:scale-100 transition ease-in-out;
}
