.control::placeholder {
    color: transparent
}

.control::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent;
}

.control::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
