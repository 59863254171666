.container {
    @apply text-neutral-600 grid grid-cols-[repeat(1,_1fr)] md:grid-cols-[repeat(4,_1fr)] sm:overflow-auto gap-3 font-medium;
}

.col {
    @apply flex flex-col;
}

.link {
    @apply rounded-xl flex gap-3 items-center;
}

.icon {
    @apply overflow-hidden relative bg-gradient-to-br flex h-10 w-10 rounded-lg items-center justify-center;
}
