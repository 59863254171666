.rc-time-picker {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
}
.rc-time-picker * {
    box-sizing: border-box;
}
.rc-time-picker-clear {
    display:none;
}
.rc-time-picker-input {
    @apply p-3 rounded-xl border-gray-200;
    width: 100%;
    position: relative;
    display: inline-block;
    cursor: text;
    background-color: #fff;
    background-image: none;
}
.rc-time-picker-input[disabled] {
    color: #ccc;
    background: #f7f7f7;
    cursor: not-allowed;
}
.rc-time-picker-panel {
    z-index: 10000;
    width: 200px;
    position: absolute;
    box-sizing: border-box;
}
.rc-time-picker-panel * {
    box-sizing: border-box;
}
.rc-time-picker-panel-inner {
    @apply rounded-xl border-gray-300 shadow-lg overflow-hidden;
    display: inline-block;
    position: relative;
    outline: none;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    background-clip: padding-box;
    line-height: 1.5;
    margin-top: 62px;
}
.rc-time-picker-panel-narrow {
    max-width: 213px;
}
.rc-time-picker-panel-input {
    display: none;
}
.rc-time-picker-panel-input-wrap {
    display: none;
}
.rc-time-picker-panel-input-invalid {
    border-color: red;
}
.rc-time-picker-panel-select::-webkit-scrollbar {
    display: none;
}

.rc-time-picker-panel-select {
    float: left;
    margin-left: -1px;
    box-sizing: border-box;
    width: 96px;
    max-height: 256px;
    overflow-y: auto;
    position: relative;
}
.rc-time-picker-panel-select-active {
    overflow-y: auto;
}
.rc-time-picker-panel-select:first-child {
    border-left: 0;
    margin-left: 0;
}
.rc-time-picker-panel-select:last-child {
    border-right: 0;
}
.rc-time-picker-panel-select ul {
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
}
.rc-time-picker-panel-select li {
    @apply px-4 py-3 text-center;
    list-style: none;
    margin: 0;
    width: 100%;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.rc-time-picker-panel-select li:hover {
    @apply bg-blue-500 text-white;
}
li.rc-time-picker-panel-select-option-selected {
    background: #f7f7f7;
    font-weight: bold;
}
li.rc-time-picker-panel-select-option-disabled {
    color: #ccc;
}
li.rc-time-picker-panel-select-option-disabled:hover {
    background: transparent;
    cursor: not-allowed;
}
