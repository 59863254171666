.crossed {
    background: #FC5230;
    height: 1px;
    left: 0;
    position: absolute;
    top: 40%;
    transform: rotateZ(-6deg);
    width: calc(100% - 1px);
    z-index: 1;
}
