.container {
    @apply select-none bg-white border rounded-xl items-center flex;
}

.btn {
    @apply p-2 rounded-lg flex-1 h-full flex items-center from-gray-500/5 cursor-pointer;
}

.qty {
    @apply px-4 font-medium relative text-center;
}
