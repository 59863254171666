.dots {
    flex: 0 1 auto;
    margin: 0 5px;
    overflow: hidden;
}

.col {
    flex: 1 0 auto;
}

.dots::before {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    opacity: 0.3;
    content:
            ". . . . . . . . . . . . . . . . . . . . "
            ". . . . . . . . . . . . . . . . . . . . "
            ". . . . . . . . . . . . . . . . . . . . "
            ". . . . . . . . . . . . . . . . . . . . "
}
