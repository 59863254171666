.wrapper {
    @apply h-full overflow-scroll;
}

.wrapper_desktop {
    @apply h-screen md:h-auto bg-white mt-3 absolute left-0 w-full justify-center pb-12;
}

.header {
    @apply z-20 fixed bg-white left-0 top-0 w-full shadow-md shadow-gray-400/10 flex flex-row items-center;
}

.backButton {
    @apply p-4 pr-3 hover:bg-gradient-to-r from-gray-200 to-transparent;
}
