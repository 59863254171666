.container {
    @apply grid gap-6 p-8 xl:border border-gray-100 rounded-xl text-lg text-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 text-gray-800 mb-2 md:mb-0;
}

.providers {
    /*hidden md:flex */
    /*@apply flex items-end justify-center sm:justify-start lg:justify-end pt-6 2xl:pr-0 opacity-70;*/
    @apply hidden md:flex px-8 justify-center sm:justify-start items-center flex opacity-70 select-none;
}

.instagram {
    @apply bg-clip-text text-transparent bg-gradient-to-r from-red-500 via-orange-500 to-purple-500;
}

.leftCol {
    @apply font-medium sm:text-left space-y-2 flex flex-col;
}

.middleCol {
    @apply font-medium sm:text-right lg:text-center space-y-2 flex flex-col;
}

.rightCol {
    @apply font-medium sm:text-left lg:text-right space-y-2 flex flex-col;
}

/*@media (max-width: 1680px) {*/
/*    .providers {*/
/*        padding-right: 80px;*/
/*    }*/
/*}*/
