.label {
    display: block;
    transform: translate(0, -2.75rem);
}


@supports (not (-ms-ime-align:auto)) {
    .label {
        color: #999;
        transform: translate(1rem, -2.4rem);
        transition: all 0.2s ease-out;pointer-events: none;
    }

    .input:focus + .label,
    .input:not(:placeholder-shown) + .label {
        font-size: 13px;
        transform: translate(1rem, -3rem);
    }
}
